(function (angular) {
    'use strict';

    angular.module('app').controller('VarianteModalController', VarianteModalController);

    function VarianteModalController(variantes, municipio) {

        var vm = this;

        vm.variantes = variantes;
        vm.municipio = municipio;
    }

})(angular);
