(function () {
    'use strict';

    angular.module('app').factory('ApiService', ApiService);

    function ApiService($http, REST_URLS) {

        return {
            obtenerCamposSemanticos: function () {
                return $http({
                    method: 'GET',
                    url: REST_URLS.CAMPOS_SEMANTICOS
                });
            },
            obtenerHiperlemas: function (codigoCs, idioma) {
                return $http({
                    method: 'GET',
                    url: REST_URLS.HIPERLEMAS,
                    params: {
                        codigoCs: codigoCs,
                        idioma: idioma
                    }
                });
            },
            obtenerLemasConVariantes: function (codigoCs, hiperlemaId) {
                return $http({
                    method: 'GET',
                    url: REST_URLS.HIPERLEMAS + '/lemas',
                    params: {
                        codigoCs: codigoCs,
                        hiperlemaId: hiperlemaId
                    }
                });
            },
            obtenerFicheroCsv: function (idioma, variantes) {
                $http({
                    method: 'POST',
                    url: REST_URLS.HIPERLEMAS + '/csv',
                    data: variantes,
                    params: {idioma: idioma},
                    responseType: "arraybuffer"
                }).then(function (result) {
                    var file = new Blob([result.data]);
                    var contentDisposition = result.headers("Content-Disposition");
                    var filename = contentDisposition ? contentDisposition.split(";")[1].trim().split('=')[1].replace(/"/g, '')
                        : "variantes_ILGA1901.csv";
                    saveAs(file, filename);
                });
            }
        }

    }
})();
