(function (angular) {
    'use strict';

    angular.module('app').factory('TranslationHandler', factory);

    factory.$inject = ['$rootScope', '$translate', '$state', '$window'];

    function factory($rootScope, $translate, $state, $window) {
        return {
            initialize: initialize,
            updateTitle: updateTitle
        };

        function initialize() {
            // refresh translate when new parts loaded
            var deregistrationTranslateRefresh =
                $rootScope.$on('$translatePartialLoaderStructureChanged',
                    function () {
                        $translate.refresh();
                    }
                );
            $rootScope.$on('$destroy', deregistrationTranslateRefresh);

            // refresh title when translation changes
            var deregistrationTranslateChange =
                $rootScope.$on('$translateChangeSuccess',
                    function () {
                        updateTitle();
                    }
                );
            $rootScope.$on('$destroy', deregistrationTranslateChange);
        }

        function updateTitle(key) {
            $translate(key || $state.$current.data && $state.$current.data.pageTitle || 'global.title')
                .then(function (title) {
                    if (key !== 'global.title') {
                        title = $translate.instant('global.title') + " - " + title;
                    }
                    $window.document.title = title;
                });
        }
    }
})(angular);
