(function (angular) {
    'use strict';

    angular.module('app').factory('StateHandler', factory);

    function factory($state, $window, $timeout, $rootScope, $translate, TranslationHandler, AlertService) {
        var _states;

        return {
            stateExists: stateExists,
            initialize: initialize
        };

        function initialize() {
            $timeout(function () {
                _states = $state.get().map(function (item) {
                    return item.name;
                });
            }, 0);
            // in every HTML view we can check for a feature/state and prevent
            // showing a link if it's not enabled
            $rootScope.stateExists = stateExists;

            var deregistrationStateChangeStart = $rootScope.$on('$stateChangeStart',
                function (event, toState, toStateParams) {
                    AlertService.clearPermanent();

                    if (toState.redirectTo && navigator.onLine) {
                        event.preventDefault();
                        $state.go(toState.redirectTo, toStateParams);
                    }

                    $rootScope.toState = toState;
                    $rootScope.toStateParams = toStateParams;
                }
            );
            $rootScope.$on('$destroy', deregistrationStateChangeStart);

            var deregistrationStateChangeSuccess = $rootScope.$on('$stateChangeSuccess',
                function (event, toState, toParams, fromState, fromParams) {
                    if ($window.ga) {
                        console.log("GoogleAnalytics. Sending: " + $location.path());
                        $window.ga('set', 'page', $location.path());
                        $window.ga('send', 'pageview');
                    }

                    if (!$rootScope.redirected && fromState.name !== 'logout' && fromState.name !== 'login') {
                        $rootScope.previousStateName = fromState.name;
                        $rootScope.previousStateParams = fromParams;
                    }

                    // Set the page title key to the one configured in state or use default one
                    var titleKey = (toState.data && toState.data.pageTitle) ? toState.data.pageTitle : 'global.title';
                    TranslationHandler.updateTitle(titleKey);

                    // Ocultamos el breadcrumb de administración
                    if (toState.data.hideBreadcrumb) {
                        angular.element(".breadcrumb-ocultable").hide();
                    } else {
                        angular.element(".breadcrumb-ocultable").show();
                    }

                    $("html, body").scrollTop(0);
                }
            );
            $rootScope.$on('$destroy', deregistrationStateChangeSuccess);

            var deregistrationStateChangeError = $rootScope.$on('$stateChangeError',
                function (event, toState, toParams, fromState, fromParams, error) {
                    console.error(error);
                });
            $rootScope.$on('$destroy', deregistrationStateChangeError);

            $rootScope.back = function () {
                // If previous state is 'activate' or do not exist go to 'home'
                if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                    $state.go('home');
                } else {
                    $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                }
            };
        }

        function stateExists(state) {
            return _states.indexOf(state) !== -1;
        }
    }
})(angular);
