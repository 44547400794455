(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:cookies
     * @description
     * Componente que añade el mensaje para "Aceptar cookies".
     *
     * Esta confirmación es obligatoria por ley siempre que se utilice Google Analytics, y en otros casos en los que se utilicen para almacenar información del usuario.
     *
     * No se necesitan cuando se utilizan para información de la sesión, por ejemplo.
     *
     * Los mensajes que se muestran __se configuran__ en `components.json`.
     *
     * __Si no se quiere mostrar el mensaje__, se puede eliminar de `index.html` el uso de este componente.
     *
     * Referencia: https://github.com/Palmabit-IT/angular-cookie-law
     *
     */
    angular
        .module('app')
        .component('cookies', {
            templateUrl: 'app/components/cookies/cookies.html',
            controllerAs: 'ctrl'
        });

})();
