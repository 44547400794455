(function () {
    'use strict';

    angular.module('app', [
        'ui.bootstrap',
        'ui.router',
        'angular-cookie-law',
        'angular-loading-bar',
        'ngAnimate',
        'ngCacheBuster',
        'ngCookies',
        'ngResource',
        'ngSanitize',
        'ngStorage',
        'pascalprecht.translate',
        'tmh.dynamicLocale',
        'checklist-model'
    ]).run(appRun);

    function appRun($rootScope, $translate, $localStorage, $q, StateHandler, TranslationHandler) {

        TranslationHandler.initialize();
        StateHandler.initialize();

        $rootScope.changeLanguage = function (key) {

            var deferred = $q.defer();
            if ($translate.use() !== key) {
                $translate.use(key).then(function () {
                    $translate.refresh();
                    deferred.resolve();
                });
                $localStorage.lang = key;
            }
            return deferred.promise;
        };
    }
})();
