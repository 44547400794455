(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:miMenu
     * @description
     * Componente que construye un menú a partir de un json.
     *
     * Parámetros para definir cada entrada del menú:
     * - label: Etiqueta
     * - icon: Clase del icono que se mostrará junto al texto
     * - state: Estado al que redirige
     * - link: Enlace al que redirige. No se puede definir `state` y `link` al mismo tiempo
     * - target: Target en caso de que se indique un link
     * - authorities: Roles que pueden ver la entrada del menú. Ej: `authorities: ROLE_ADMIN, ROLE_COLABORADOR`
     * - states: Lista de estados en los cuales se muestra la entrada del menú
     * - actives: Lista de estados en los cuales la entrada del menú se marcará como activa
     * - modal: Permite abrir una ventana modal estática pasándole el ID
     * - componente: Componente HTML.
     * - subtree: Definición del submenú.
     *    - label: Etiqueta que se mostrará. Si se quiere definir como una línea separadora, hay que indicar el texto: `divider`
     *    - icon: Clase del icono
     *    - state: Estado
     *    - link: Enlace
     *    - target: Target en caso de que se indique un link
     *
     * Referencia: {@link https://angular-ui.github.io/bootstrap/#dropdown}
     *
     * @param {Object} menu Objeto que contiene todas las entradas del menú y sus propiedades.
     * @param {Boolean} navCollapsed Booleano para mostrar la barra colapsada o expandida.
     * Es necesario porque si se pone siempre a true, cuando es en escritorio hace una transición que queda mal, ocultándose y mostrándose.
     * Tiene que ir a true sólo en dispositivos móviles.
     * @param {Boolean} lateral Booleano para indicar si es un menú lateral o normal.
     *
     * @requires app.directive:submenu
     * @requires app.directive:hasAnyAuthority
     * @requires app.service:EstaticaModal
     */
    angular
        .module('app')
        .component('miMenu', {
            bindings: {
                menu: '<',
                navCollapsed: '<',
                lateral: '<'
            },
            templateUrl: 'app/components/menu/menu.html',
            /* @ngInject */
            controller: function ($window, $state) {
                var vm = this;

                vm.checkActiveStates = function (activeStates) {
                    var active = false;
                    if (activeStates) {
                        var states = activeStates.replace(/\s+/g, '').split(',');
                        states.forEach(function (state) {
                            if ($state.includes(state)) {
                                active = true;
                            } else {
                                var estadoActualString = $state.current.name + '(' + JSON.stringify($state.params) + ')';
                                if (state === estadoActualString) {
                                    active = true;
                                }
                            }
                        });
                    }
                    return active;
                };

                vm.collapse = function () {
                    if ($window.innerWidth < 768) {
                        vm.navCollapsed = !vm.navCollapsed;
                    }
                }
            },
            controllerAs: 'ctrl'
        });
})(angular);
