(function () {
    'use strict';

    angular.module('app').config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider.state('site', {
            abstract: true,
            resolve: {
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('public');
                }
            }
        });
    }
})();
