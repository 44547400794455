(function () {
    'use strict';

    angular.module('app').config(localStorageConfig);

    function localStorageConfig($localStorageProvider, $translateProvider, LANGUAGES) {

        $localStorageProvider.setKeyPrefix("ILGA-");

        // Obtenemos el idioma de localStorage
        var lang = $localStorageProvider.get('lang');
        if (!lang || !LANGUAGES.list.includes(lang)) {
            $translateProvider.preferredLanguage(LANGUAGES.default);
            $localStorageProvider.set('lang', LANGUAGES.default);
        } else {
            $translateProvider.preferredLanguage(lang);
        }
    }

})();
