(function (angular) {
    'use strict';

    angular.module('app').config(config);

    function config($translateProvider, $localStorageProvider, tmhDynamicLocaleProvider) {

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.useLoaderCache(true);
        $translateProvider.useSanitizeValueStrategy('escaped');
        // to use messageformat.js https://github.com/messageformat/messageformat.js
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale/angular-locale_{{locale}}.js');
        // El idioma se guarda en localStorage en localstorage.config.js
    }


})(angular);
