(function () {
    'use strict';

    /**
     * @ngdoc filter
     * @name app.filter:trustUrl
     * @description
     * Permite utilizar URLs en actions directamente.
     *
     * @returns {String} Se indica que la URL es de confianza.
     */
    angular
        .module('app')
        .filter('trustUrl', filter);

    /* @ngInject */
    function filter($sce) {
        return filter;

        function filter(url) {
            return $sce.trustAsResourceUrl(url);
        }
    }
})();
