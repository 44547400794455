(function (angular) {
    'use strict';

    angular.module('app').controller('HeaderController', HeaderController);

    function HeaderController() {

        var vm = this;
    }

})(angular);
