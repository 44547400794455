(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:RutasService
     * @description
     * Operaciones relacionadas con las rutas de la aplicación.
     *
     */
    angular.module('app').factory('RutasService', RutasService);

    /* @ngInject */
    function RutasService($location, $window, CONTEXT, DEBUG_INFO_ENABLED) {
        var cacheUrl = "";

        return {
            /**
             * @ngdoc function
             * @name getBaseUrl
             * @methodOf app.service:RutasService
             * @param {Boolean} [cliente=false] Booleano para saber si queremos la ruta del lado cliente o del lado servidor.
             * @returns {String} Ruta base de la aplicación.
             */
            getBaseUrl: getBaseUrl,
            /**
             * @ngdoc function
             * @name getUrlParam
             * @methodOf app.service:RutasService
             * @param {String} paramName Nombre del parámetro.
             * @returns {String} Valor del parámetro de la URL.
             */
            getUrlParam: getUrlParam,
            /**
             * @ngdoc function
             * @name getContext
             * @methodOf app.service:RutasService
             * @returns {String} Contexto de la aplicación.
             */
            getContext: getContext,

            // TODO: Mover a un servicio particular de la aplicación.
            getAgenteCvUrl: getAgenteCvUrl,
            getAgenteImagenUrl: getAgenteImagenUrl
        };

        function getBaseUrl(cliente) {
            // En desarrollo utilizamos la de pre porque si no hay problemas al compartir con Facebook
            if (!cacheUrl) {
                if (DEBUG_INFO_ENABLED) {
                    if (cliente) {
                        cacheUrl = "http://localhost:9000/#";
                    } else {
                        cacheUrl = "http://localhost:8080";
                    }
                } else {
                    cacheUrl = $location.protocol() + "://" + $location.host();
                    if ($location.port() !== 80 && $location.port() !== 443) {
                        cacheUrl = cacheUrl + ":" + $location.port();
                    }
                }
                cacheUrl = cacheUrl + getContext();
            }
            return cacheUrl;
        }

        function getUrlParam(paramName) {
            var reParam = new RegExp('(?:[\?&]|&)' + paramName + '=([^&]+)', 'i');
            var match = $window.location.href.match(reParam);

            return (match && match.length > 1) ? match[1] : null;
        }

        function getContext() {
            var context = CONTEXT;
            if (CONTEXT) {
                context = "/" + CONTEXT;
            }
            return context;
        }

        function getAgenteCvUrl(id) {
            return '/upload/agentes/' + id + '/cv/';
        }

        function getAgenteImagenUrl(id) {
            return '/upload/agentes/' + id + '/imagen/';
        }

    }

})();
