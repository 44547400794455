(function () {
    'use strict';

    angular.module('app').config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider.state('public', {
            abstract: true,
            parent: 'site',
            views: {
                'css@': {
                    templateUrl: 'app/public/public.css.html'
                },
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: "HeaderController",
                    controllerAs: 'vm'
                },
                'navbar@': {
                    template: '<mi-menu menu="vm.menu" nav-collapsed="vm.navCollapsed"></mi-menu>',
                    controller: function (menuData, $window) {
                        this.menu = menuData;
                        this.navCollapsed = $window.innerWidth < 992;
                    },
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html'
                }
            },
            resolve: {
                menuData: function (Menu) {
                    return Menu.getMenu('menu-main');
                }
            }
        });
    }
})();
