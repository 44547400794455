(function (angular) {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:ModalService
     * @description
     * Gestión de ventanas modales.
     *
     * Permite tener varias abiertas e ir cerrándolas por orden inverso de apertura.
     *
     * Es importante que todas las ventanas modales se abran y se cierren utilizando este servicio.
     *
     * Referencia: {@link https://angular-ui.github.io/bootstrap/#modal}
     *
     */
    angular.module('app')
        .service('ModalService', ModalService);

    /* @ngInject */
    function ModalService($state, $uibModal, AlertService) {
        var opened = 0;
        var modals = [];

        return {
            /**
             * @ngdoc function
             * @name isOpen
             * @methodOf app.service:ModalService
             *
             * @returns {Boolean} Booleano para saber si hay alguna ventana modal abierta
             */
            isOpen: function () {
                return opened;
            },
            /**
             * @ngdoc function
             * @name open
             * @methodOf app.service:ModalService
             * @description
             * Abrir ventana modal.
             *
             * @param {Object} params Campos necesarios para constuir una ventana modal
             *
             * @returns {Object} Ventana modal
             */
            open: open,
            /**
             * @ngdoc function
             * @name openComponent
             * @methodOf app.service:ModalService
             * @description
             * Abrir componente en una ventana modal.
             *
             * @param {String} title Título de la ventana modal
             * @param {Object} configEntity Constante de donde se obtendrá el component y el resolve. (Ej: AGENTE_FORM_ADMIN)
             * @param {Object} [params={size: 'lg', backdrop: 'static'}] Parámetros adicionales
             *
             * @returns {Object} Ventana modal
             */
            openComponent: openComponent,
            /**
             * @ngdoc function
             * @name closeComponent
             * @methodOf app.service:ModalService
             * @description
             * Submit del componente. Se cierra la ventana modal o se cambia de estado.
             *
             * @param {Object} result Resultado de la operación.
             * @param {String} state Estado al que se va a dirigir.
             * @param {Object} [params={}] Parámetros del estado.
             */
            closeComponent: closeComponent,
            /**
             * @ngdoc function
             * @name openImagen
             * @methodOf app.service:ModalService
             * @description
             * Abrir una imagen en una ventana modal.
             *
             * El css relacionado está en `comun.scss` (clase `.imagen-modal`).
             *
             * @param {String} path URL a la imagen
             * @param {Object} [params={}] Parámetros adicionales
             *
             * @returns {Object} Ventana modal
             */
            openImagen: openImagen,
            /**
             * @ngdoc function
             * @name close*
             * @methodOf app.service:ModalService
             *
             * @param {Object} data Resultado de la operación.
             *
             */
            close: close,
            /**
             * @ngdoc function
             * @name dismiss
             * @methodOf app.service:ModalService
             *
             * @param {Object} data Resultado de la operación.
             *
             */
            dismiss: dismiss
        };

        function open(params) {
            opened = opened + 1;
            var modal = $uibModal.open(params);
            modals.push(modal);

            // En caso de que se pulse fuera de la modal, hacemos un dismiss
            modal.result.catch(function (data) {
                if (data === 'backdrop click') {
                    dismiss(data);
                }
            });

            AlertService.clear();
            return modal;
        }

        function openComponent(title, configEntity, params) {
            if (!params) params = {};

            var config = configEntity(true);
            params.resolve = config.resolve;
            params.resolve.component = function () {
                return config.component;
            };

            return open(angular.merge({}, {
                backdrop: 'static',
                size: 'lg',
                component: 'entityModal',
                resolve: {
                    title: function () {
                        return title;
                    }
                }
            }, params));
        }

        function closeComponent(result, state, params) {
            if (opened) {
                AlertService.clear();
                close(result);
            } else {
                $state.go(state, params);
            }
        }

        function openImagen(path, params) {
            if (!params) params = {};

            return open(angular.extend({}, {
                template: '<a ng-click="ctrl.close()"><img src="' + path + '" /></a>',
                windowClass: 'imagen-modal',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService) {
                    this.close = function () {
                        ModalService.close();
                    }
                }
            }, params));
        }


        function close(data) {
            subOpened();
            modals[modals.length - 1].close(data);
            modals.pop();
        }

        function dismiss(data) {
            subOpened();
            modals[modals.length - 1].dismiss(data);
            modals.pop();
        }

        function subOpened() {
            opened = opened - 1;
            if (opened < 0) {
                opened = 0;
            }
        }
    }

})(angular);
