(function () {
    'use strict';

    angular.module('app').config(appConfig);

    function appConfig(cfpLoadingBarProvider, $compileProvider, $qProvider) {

        // Configuración de la barra de carga
        cfpLoadingBarProvider.latencyThreshold = 150;
        cfpLoadingBarProvider.includeSpinner = false;

        $compileProvider.debugInfoEnabled(true);
        $compileProvider.preAssignBindingsEnabled(true);

        $qProvider.errorOnUnhandledRejections(false);
    }
})();
