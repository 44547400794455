(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:labelForm
     *
     * @param {String} key Texto a internacionalizar
     * @param {String} for ID del input asociado
     * @param {Boolean} [puntos=false] Añadir dos puntos al final de la etiqueta
     * @param {String} [tooltip=undefined] Mensaje del tooltip
     * @param {String} [tooltipPosition='bottom'] Posición del tooltip
     * @param {Boolean} [required=false] Campo obligatorio
     *
     * @requires app.component:tooltip
     */
    angular
        .module('app')
        .component('labelForm', {
            template: '<label ng-if="ctrl.key" class="control-label" ng-class="{\'required\' : ctrl.required}" for="{{ctrl.for}}">' +
                '{{ctrl.key | translate}}{{ctrl.sufijo}}<span ng-if="ctrl.required">*</span><span ng-if="ctrl.puntos">:</span>' +
                '</label><tooltip ng-if="ctrl.tooltip" label="{{ctrl.tooltip}}" position="{{ctrl.tooltipPosition}}"></tooltip>',
            bindings: {
                key: '@', // required
                sufijo: '@',
                for: '@',
                puntos: '<',
                tooltip: '@',
                tooltipPosition: '@',
                required: '<'
            },
            controllerAs: 'ctrl'
        });

})();
