(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('app')
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('LANGUAGES', {
	"default": "gl",
	"list": [
		"gl",
		"pt",
		"en"
	]
})
        .constant('COMPONENTES', {
	"aulaDigital": {
		"nivelesAdmin": "all"
	},
	"contacto": {
		"captcha": "all"
	},
	"feedsRss": {
		"categoriasAdmin": "all"
	},
	"novedades": {
		"categoriasAdmin": "all",
		"categoriasPublic": "all"
	},
	"webs": {
		"categoriasAdmin": "create"
	}
})
        .constant('REST_URLS', {
	"ENTRADAS": "/api/entradas",
	"HIPERLEMAS": "/api/hiperlemas",
	"CAMPOS_SEMANTICOS": "/api/campos-semanticos"
})
;
})();
