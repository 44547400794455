(function (angular) {
    'use strict';

    angular.module('app').controller('HomeController', HomeController);

    function HomeController($filter, ApiService, $localStorage, $uibModal, $timeout) {

        var vm = this;

        vm.selectLemas = [];
        vm.lemas = [];

        vm.actualizarConceptos = actualizarConceptos;
        vm.actualizarLemas = actualizarLemas;
        vm.obtenerCampoSemantico = obtenerCampoSemantico;
        vm.obtenerHiperlema = obtenerHiperlema;

        vm.descargarMapa = descargarMapa;
        vm.descargarDatos = descargarDatos;
        vm.actualizarMapa = actualizarMapa;
        vm.cambiarEstiloLayer = cambiarEstiloLayer;
        vm.obtenerLetraOrden = obtenerLetraOrden;
        vm.obtenerCampoClase = obtenerCampoClase;
        vm.obtenerClaseLetra = obtenerClaseLetra;
        vm.marcarLemas = marcarLemas;
        vm.marcarTodosLemas = true;

        activate();

        function activate() {

            ApiService.obtenerCamposSemanticos().then(function (response) {
                vm.camposSemanticos = response.data;
            });

            ApiService.obtenerHiperlemas(null, $localStorage.lang).then(function (response) {
                vm.hiperlemas = response.data;
            });

            vm.estilosPopover = {
                templateUrl: 'app/public/home/popover/estilos-popover.html',
                title: $filter('translate')('mapa.estilos')
            };

            vm.variantesPopover = {
                templateUrl: 'app/public/home/popover/variantes-popover.html',
                title: $filter('translate')('home.variantes')
            };

            vm.estiloMarcadorDefecto = "marker-estilo-1 ";
            vm.estiloForma = vm.estiloMarcadorDefecto;

            inicializarMapa();
        }

        function inicializarMapa() {

            var initWidth = $(window).width(), initHeight = $(window).height();

            // Definición del mapa
            vm.map = L.map('visor', {
                zoomControl: false,
                minZoom: 6
            });

            // Resize tamaño mapa
            $(window).on("resize", function () {

                if (!vm.gallaeciaLayer || ($(window).width() != initWidth || $(window).height() != initHeight)) {

                    initWidth = $(window).width();
                    initHeight = $(window).height();

                    // Tamaña do header máis footer
                    var headersPx = 44 + 65;
                    // Cando o alto da pantalla é pequena, poñemos un tamaño fijo do mapa
                    var height = window.innerHeight < 400 ? 225 :
                        (window.innerWidth > 767 ? window.innerHeight - headersPx : window.innerHeight - headersPx - 200);

                    $("#visor").height(height);
                    $("#select-lemas").css({"maxHeight": (height - 285) + 'px'});
                    vm.map.invalidateSize();

                    if (vm.gallaeciaLayer)
                        vm.map.fitBounds(vm.gallaeciaLayer.getBounds());
                }
            }).trigger("resize");

            var osmLayer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data © <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                detectRetina: true
            }).addTo(vm.map);

            // Control do zoom do mapa
            var zoomControl = new L.control.zoom({
                zoomInTitle: $filter('translate')('mapa.zoom.mais'),
                zoomOutTitle: $filter('translate')('mapa.zoom.menos')
            }).addTo(vm.map);

            // Controis do mapa
            var scaleControl = new L.control.scale({'imperial': false}).addTo(vm.map);

            // Plugin configuración completa
            vm.map.addControl(new L.Control.Fullscreen({
                title: {
                    'false': $filter('translate')('mapa.pantallaCompleta.entrar'),
                    'true': $filter('translate')('mapa.pantallaCompleta.sair')
                }
            }));

            // Configuración plugin exportar PNG
            vm.printer = L.easyPrint({
                tileLayer: osmLayer,
                position: 'bottomright',
                exportOnly: true,
                hidden: true
            }).addTo(vm.map);

            // Concellos de Gallaecia
            vm.gallaeciaLayer = L.geoJson(gallaeciaJSON, {
                style: {
                    "color": "#fe9929",
                    "weight": 1,
                    "opacity": 0.75
                }
            }).addTo(vm.map);

            // Actualizamos a vista do mapa co maior nivel de zoom posible
            vm.map.fitBounds(vm.gallaeciaLayer.getBounds());

            // Control para centrar Gallaecia no mapa
            createControl($filter('translate')('mapa.centrar'), 'center.png');
        }

        function layersEqual(layers, lema) {
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].lema !== lema)
                    return false;
            }
            return true;
        }

        function actualizarMapa(lema) {

            if (!lema.checked) {

                var coordenadasCoincidentes = obtenerCoordenadasCoincidentes(lema.orden);
                removeLayers(lema.orden);

                angular.forEach(coordenadasCoincidentes, function (coordenadas) {

                    // Ordenamos la lista según el orden
                    function ordenarLayers(a, b) {
                        if (a.lema.orden < b.lema.orden)
                            return -1;
                        if (a.lema.orden > b.lema.orden)
                            return 1;
                        return 0;
                    }

                    var layers = [];
                    vm.map.eachLayer(function (layer) {
                        if (layer.lema && layer._latlng.lat === coordenadas.lat && layer._latlng.lng === coordenadas.lng)
                            layers.push(layer);
                    });

                    if (layers.length > 0) {
                        layers.sort(ordenarLayers);
                        if (layers.length === 1) {
                            actualizarLayer(layers[0], layers[0].lema.clase);
                        } else if (layers.length > 1) {
                            angular.forEach(layers, function (layer, key) {
                                vm.map.removeLayer(layer);
                                if (layersEqual(layers, layer.lema)) {
                                    layer.options.icon.options.className = layer.lema.clase;
                                } else {
                                    layer.options.icon.options.className
                                        = (key === 0) ? layer.lema.clase + ' marker-especial' : layer.lema.clase + ' marker-invisible';
                                }
                                vm.map.addLayer(layer);
                            });
                        }
                    }
                });

            } else {

                // Engadimos as variantes do lema ao mapa
                angular.forEach(lema.variantes, function (variante, key) {

                    // Centroides de Gallaecia
                    var layerGeoJSON = L.geoJson(gallaeciaCentroidesJSON, {
                        onEachFeature: function (feature, layer) {
                            layer.lema = lema;
                        },
                        filter: function (feature, layer) {
                            var codMun = feature.properties.COD_MUN ? feature.properties.COD_MUN : feature.properties.CODIGOINE;
                            return variante.locs.indexOf(codMun) > -1;
                        },
                        pointToLayer: function (feature, latlng) {

                            var codMun = feature.properties.COD_MUN ? feature.properties.COD_MUN : feature.properties.CODIGOINE;
                            var nome = feature.properties.MUNICIPIO ? feature.properties.MUNICIPIO : feature.properties.NAMEUNIT;
                            var esRombo = lema.clase.indexOf('marker-estilo-4') > -1;

                            // Definición do marcador
                            var marker = L.marker(latlng, {
                                icon: obterIcona(lema),
                                title: nome,
                                rotationAngle: esRombo ? 45 : 0
                            });

                            // Evento click
                            marker.on('click', function (e) {

                                var variantesLema = [];
                                // Un mesmo lema pode ter varias variantes no mesmo lema.
                                angular.forEach(lema.variantes, function (variante) {
                                    if (variante.locs.indexOf(codMun) > -1)
                                        variantesLema.push(variante);
                                });

                                // Cada vez que facemos clic sobre o marcador, calculamos as variantes do concello
                                var lemasConcello = [];

                                lemasConcello.push({
                                    nombre: lema.nombre,
                                    orden: lema.orden,
                                    variantes: variantesLema,
                                    numeroConcellos: lema.numeroConcellos
                                });

                                vm.map.eachLayer(function (layer) {

                                    if (layer.lema) {
                                        var variantesLayerLema = [];
                                        angular.forEach(layer.lema.variantes, function (variante) {
                                            // Comprobamos se o concello coincide con algún dos que xa temos no mapa
                                            if (variante.locs.indexOf(codMun) > -1)
                                                variantesLayerLema.push(variante);
                                        });

                                        if (variantesLayerLema && variantesLayerLema.length > 0) {
                                            var novoLema = {
                                                nombre: layer.lema.nombre,
                                                orden: layer.lema.orden,
                                                variantes: variantesLayerLema,
                                                numeroConcellos: layer.lema.numeroConcellos
                                            };

                                            if (!contieneObjeto(novoLema, lemasConcello))
                                                lemasConcello.push(novoLema);
                                        }
                                    }
                                });

                                // Ordenamos los lemas
                                lemasConcello.sort(ordenarVariantes);

                                //crearModal(variantesConcello, nome);

                                function getUrlTesouro(modo, lema) {
                                    return "http://ilg.usc.gal/Tesouro/gl/search#search=normal&mode=" + modo + "&q=" + lema;
                                }

                                function infoPopup(concello, lemas) {
                                    var html = "<h1 class='titulo-popup'><b>" + concello + "</b></h1><hr style='margin: 0px'/>";
                                    angular.forEach(lemas, function (lema) {

                                        var lemas = lema.nombre.split("/");

                                        html += "<h5 style='text-align: left; margin-bottom: 5px;'>";
                                        angular.forEach(lemas, function (lema, key) {
                                            html += "<a target='_blank' href='" + getUrlTesouro("lema", lema) + "'><b>" + lema + "</b></a>";
                                            if (lemas.length > 1 && key !== (lemas.length - 1))
                                                html += "/";
                                        });
                                        html += "<div style='float: right; cursor: auto !important;' class='badge badge-lemas'>" + lema.numeroConcellos + "</div></h5>"

                                        // Variantes do lema
                                        angular.forEach(lema.variantes, function (variante) {
                                            var nombreVariante = variante.nombre;
                                            html += "<p class='texto-popup'>* <a target='_blank' href='" + getUrlTesouro("variante", nombreVariante) + "'>" + nombreVariante + "</a> ("
                                                + variante.ocurrencias + ")</p>";
                                        });

                                    });
                                    return html;
                                }

                                var info = infoPopup(nome, lemasConcello);

                                // Ao cambiar o tamaño do marker, tamén debemos actualizar o desplazamento do popup
                                if (esRombo)
                                    marker.options.icon.options.popupAnchor = lemasConcello.length > 1 ? [10.5, -10] : [7.5, -10];
                                else
                                    marker.options.icon.options.popupAnchor = lemasConcello.length > 1 ? [2, -8] : [0, -8];

                                if (e.target.getPopup())
                                    marker.setPopupContent(info);
                                else
                                    marker.bindPopup(info).openPopup();

                            });

                            // Marcadores especial (en caso de superpoñerse)
                            vm.map.eachLayer(function (layer) {
                                if (layer.lema && latlng.lat === layer._latlng.lat && latlng.lng === layer._latlng.lng
                                    && layer.lema.orden !== lema.orden) {
                                    if (layer.lema.orden > lema.orden) {
                                        actualizarLayer(layer, layer.options.icon.options.className += ' marker-invisible');
                                        marker.options.icon.options.className += ' marker-especial';
                                    } else {
                                        // Ao punto que xa temos cambíamoslle o estilo e non engadimos ningún novo
                                        actualizarLayer(layer, layer.options.icon.options.className += ' marker-especial');
                                        marker.options.icon.options.className += ' marker-invisible';
                                    }
                                }
                            });

                            return marker;
                        }
                    }).addTo(vm.map);
                });
            }
        }

        function crearModal(variantesConcello, nome) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/public/home/modal/variante-modal.html',
                controller: 'VarianteModalController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    variantes: function () {
                        return variantesConcello;
                    },
                    municipio: function () {
                        return nome;
                    }
                }
            });

            modalInstance.result.then(function () {
                //
            }, function () {
                // Click fora, dismiss
            });
        }

        function descargarMapa() {
            vm.printer.printMap('CurrentSize', 'mapa_ILG1901');
        }

        function descargarDatos() {
            if (vm.selectLemas) {
                vm.selectLemas.sort(ordenarVariantes);
                ApiService.obtenerFicheroCsv($localStorage.lang, vm.selectLemas);
            }
        }

        function actualizarLayer(layer, clase) {
            vm.map.removeLayer(layer);
            layer.options.icon.options.className = clase;
            vm.map.addLayer(layer);
        }

        function removeLayers(orden) {
            vm.map.eachLayer(function (layer) {
                if (layer.lema && layer.lema.orden === orden)
                    vm.map.removeLayer(layer);
            });
        }

        function removeAllLayers() {
            vm.map.eachLayer(function (layer) {
                if (layer.lema)
                    vm.map.removeLayer(layer);
            });
        }

        function obtenerCoordenadasCoincidentes(orden) {

            var coordenadasCoincidentes = [];
            vm.map.eachLayer(function (layer) {
                if (layer.lema && layer.lema.orden === orden
                    && coordenadasCoincidentes.indexOf(layer._latlng) < 0)
                    coordenadasCoincidentes.push(layer._latlng);
            });

            return coordenadasCoincidentes;
        }

        function actualizarConceptos(codigoCs) {
            vm.lemas = [];
            vm.hiperlemas = [];
            ApiService.obtenerHiperlemas(codigoCs, $localStorage.lang).then(function (response) {
                vm.hiperlemas = response.data;
            });
        }

        function marcarLemas(todos) {
            angular.forEach(vm.lemas, function (lema) {
                lema.checked = todos;
                if (todos) {
                    vm.selectLemas.push(lema);
                } else {
                    vm.selectLemas = [];
                    removeAllLayers()
                }
            });
        }

        function actualizarLemas(codigoCs, hiperlema) {
            vm.lemas = [];
            vm.selectLemas = [];
            removeAllLayers();
            if (hiperlema)
                ApiService.obtenerLemasConVariantes(codigoCs, hiperlema.id).then(function (response) {
                    vm.lemas = response.data;
                    vm.marcarTodosLemas = true;
                    // Marcar todos os lemas
                    $timeout(function () {
                        marcarLemas(vm.marcarTodosLemas);
                    }, 0);
                });
        }

        function obtenerClaseOrden(orden) {
            // Creamos un intervalo para 12 cores
            var clase = 'marker-orden-'
            return (orden % 12 == 0) ? clase + '12' : clase + orden % 12;
        }

        function cambiarEstiloLayer(lema, estilo, ordenColor) {
            lema.popoverAberto = false;
            lema.claseColor = ordenColor ? 'marker-orden-' + ordenColor :
                (lema.claseColor ? lema.claseColor : obtenerClaseOrden(lema.orden));
            lema.estiloForma = estilo ? estilo : vm.estiloForma;
            lema.clase = lema.estiloForma + ' ' + lema.claseColor;
            if (lema.checked) {
                removeLayers(lema.orden);
                actualizarMapa(lema);
            }
        }

        function obtenerCampoClase(lema) {
            if (lema && !lema.clase)
                lema.clase = vm.estiloMarcadorDefecto + obtenerClaseOrden(lema.orden);
            return lema.clase;
        }

        function obtenerCampoSemantico(cs) {
            if (cs && cs.codigo && cs.nombreCorto) {
                switch ($localStorage.lang) {
                    case 'pt':
                        return cs.codigo + " " + cs.nombreCortoPt;
                    case 'en':
                        return cs.codigo + " " + cs.nombreCortoEn;
                    default:
                        return cs.codigo + " " + cs.nombreCorto;
                }
            }
        }

        function obtenerHiperlema(h) {
            if (h && (h.hiperlema || h.hiperlemaPt)) {
                switch ($localStorage.lang) {
                    case 'pt':
                        return h.hiperlemaPt ? h.hiperlemaPt : h.hiperlema;
                    default:
                        return h.hiperlema;
                }
            }
        }

        // Función auxiliar para a creación de botóns auxiliares no mapa
        function createControl(title, nameFile) {

            var boton = L.control({
                position: 'topleft'
            });

            var container = L.DomUtil.create('div', 'leaflet-control leaflet-bar container');

            boton.onAdd = function (map) {
                container.title = title;
                container.style.width = '30px';
                container.style.height = '34px';
                container.style.backgroundImage = 'url(assets/images/' + nameFile + ')';
                container.style.backgroundRepeat = 'no-repeat';
                container.style.backgroundSize = '30px';
                container.style.backgroundColor = '#FFFFFF';
                container.style.cursor = 'pointer';

                // Evento click sobre o botón
                container.onclick = function () {
                    map.fitBounds(vm.gallaeciaLayer.getBounds());
                };

                return container;
            }

            boton.addTo(vm.map);
        }

        // iconUrl: 'https://wals.info/static/icons/d090.png',
        function obterIcona(lema) {

            var esRombo = lema.clase.indexOf('marker-estilo-4') > -1;
            var letra = obtenerLetraOrden(lema.orden);

            return L.divIcon({
                popupAnchor: [0, -8],
                className: lema.clase,
                iconSize: [20, 20],
                html: esRombo ? '<div class="rotar-letra-rombo">' + letra + '</div>' : letra
            });
        }

        function obtenerLetraOrden(orden) {
            if (orden < 27) {
                // Maiúsculas
                return String.fromCharCode(64 + orden);
            } else if (orden >= 27 && orden < 53) {
                // Minúsculas
                return String.fromCharCode(70 + orden)
            } else if (orden >= 53) {
                // Números
                return orden - 52;
            }
        }

        function ordenarVariantes(a, b) {
            if (a.numeroConcellos < b.numeroConcellos)
                return 1;
            if (a.numeroConcellos > b.numeroConcellos)
                return -1;
            if (a.numeroConcellos === b.numeroConcellos) {
                var nombreA = a.nombre.toLowerCase();
                var nombreB = b.nombre.toLowerCase();
                if (nombreA < nombreB)
                    return -1;
                if (nombreA > nombreB)
                    return 1;
            }
            return 0;
        }

        function contieneObjeto(obj, list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].nombre === obj.nombre && list[i].orden === obj.orden) {
                    return true;
                }
            }
            return false;
        }

        function obtenerClaseLetra(clase) {
            return clase.indexOf('marker-estilo-4') > -1 ? 'rotar-letra-rombo' : '';
        }

    }

})(angular);
